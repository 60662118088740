@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.container {
  padding-top: $spacing-m;
  padding-bottom: $spacing-m;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;

  @media (min-width: $mq-medium-and-above) {
    flex-direction: row;
  }

  &-boxed {
    padding: $spacing-xs;
    border-radius: $border-radius-s;

    @media (min-width: $mq-medium-and-above) {
      padding: $spacing-m $spacing-s;
    }
  }
}

.icon {
  @media (min-width: $mq-medium-and-above) {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: math.div(100%, 12) * 2;
  }

  & > svg {
    display: block;
  }

  @each $color-name, $color-value in $colors {
    &-color-#{'' + $color-name} {
      @include get-text-color($color-value);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;

  @media (min-width: $mq-medium-and-above) {
    padding: 0 $spacing-xxs;
  }
}

@each $color-name, $color-value in $cms-colors {
  .bg-#{'' + $color-name} {
    @include background-text-color($color-value);
  }
}
